import {
  SET_FIRM_FIELD,
  SET_LAW_FIRMS_LIST,
  SET_REMAINING_ATTEMPTS,
  SUBMISSION_ERROR,
  SET_SUBMISSION_RESULT,
  SUBMIT_LAW_FIRM_SELECTION,
  SUBMIT,
  UPDATE_FIELD,
  SEARCH_LAW_FIRM,
  RESET_LAW_FIRM_RESULTS,
  SELECT_LAW_FIRM,
  RESET_SELECTED_LAW_FIRM,
} from "../constants/account-details";

export const updateField = payload => ({
  type: UPDATE_FIELD,
  ...payload,
});

export const submit = bankDetails => ({
  type: SUBMIT,
  bankDetails,
});

export const setRemainingAttempts = payload => ({
  type: SET_REMAINING_ATTEMPTS,
  ...payload,
});

export const setFirmField = payload => ({
  type: SET_FIRM_FIELD,
  ...payload,
});

export const setLawFirmsList = list => ({
  type: SET_LAW_FIRMS_LIST,
  list,
});

export const submitLawFirmSelection = () => ({
  type: SUBMIT_LAW_FIRM_SELECTION,
});

export const setSubmissionResult = payload => ({
  type: SET_SUBMISSION_RESULT,
  ...payload,
});

export const setSubmissionError = () => ({
  type: SUBMISSION_ERROR,
});

export const searchLawFirm = (postCode, firmName) => ({
  type: SEARCH_LAW_FIRM,
  postCode,
  firmName,
});

export const resetLawFirmResults = () => ({
  type: RESET_LAW_FIRM_RESULTS,
});

export const selectLawFirm = firm => ({
  type: SELECT_LAW_FIRM,
  firm,
});

export const resetSelectedLawFirm = () => ({
  type: RESET_SELECTED_LAW_FIRM,
});
