export default theme => ({
  modal: {
    position: 'relative',
  },
  closeModalButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  paperModal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 4 * theme.spacing.unit,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.spacing.unit * 50,
    maxWidth: `calc(100vw - ${6 * theme.spacing.unit}px)`,
    maxHeight: `calc(100vh - ${6 * theme.spacing.unit}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  headingModal: {
    position: 'relative',
    paddingBottom: 0.5 * theme.spacing.unit,
    marginBottom: 0.5 * theme.spacing.unit,
    '&::after': {
      content: '""',
      width: 1 * theme.spacing.unit,
      height: 0.25 * theme.spacing.unit,
      position: 'absolute',
      backgroundColor: theme.palette.text.primary,
      bottom: 0,
      left: 0,
    },
  },
  containerModal: {
    overflowY: 'scroll',
  },
  paragraphModal: {
    marginBottom: theme.spacing.unit,
  },
});
