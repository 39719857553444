import emailValidator from "email-validator";
import { isNil } from "ramda";

export function isPostCode(str) {
  const rg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/i;
  return rg.test(str);
}

export default class Validator {
  constructor(value) {
    this.value = value;
    this.isValid = true;
    this.errorMessage = ``;
  }

  isEmail = () => {
    /* istanbul ignore else */
    if (this.isValid) {
      this.isValid = emailValidator.validate(this.value);

      if (!this.isValid) {
        this.errorMessage = `The email address you have provided is not valid, please re-enter your email address correctly as this will be used to send you a personalised link to access Bank Account Check.`;
      }
    }

    return this;
  };

  isName = () => {
    /* istanbul ignore else */
    if (this.isValid) {
      const validSurnameRegEx = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
      this.isValid = validSurnameRegEx.test(this.value);

      if (!this.isValid) {
        this.errorMessage = `Invalid characters`;
      }
    }

    return this;
  };

  isPostCode = () => {
    /* istanbul ignore else */
    if (this.isValid) {
      const validPostCodeRegEx = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
      this.isValid = validPostCodeRegEx.test(this.value);

      if (!this.isValid) {
        this.errorMessage = `The postcode you have provided is not in a valid format, please re-enter your postcode`;
      }
    }

    return this;
  };

  hasMinLength = (minLength) => {
    if (this.isValid) {
      this.isValid = this.value && String(this.value).length >= minLength;

      if (!this.isValid) {
        this.errorMessage = `The value should have at least ${minLength} characters`;
      }
    }

    return this;
  };

  isReCAPTCHA = () => {
    /* istanbul ignore else */
    if (this.isValid) {
      this.isValid = !isNil(this.value);
      this.isRequired();
    }

    return this;
  };

  isRequired = () => {
    this.hasMinLength(1);

    if (!this.isValid) {
      this.errorMessage = `We need this information to proceed`;
    }

    return this;
  };

  isChecked = () => {
    if (this.isValid) {
      this.isValid = this.value === true;

      if (!this.isValid) {
        this.errorMessage = `Without agreeing to the Terms & Conditions you are unable to use LMS Bank Account Check`;
      }
    }

    return this;
  };

  isAccountNumber = () => {
    if (this.isValid) {
      const isNumber8CharsLengthRegEx = /[0-9]{8}/;
      this.isValid = this.value && isNumber8CharsLengthRegEx.test(this.value);

      if (!this.isValid) {
        this.errorMessage = `The account number you have provided is not in a valid format, please re-enter`;
      }
    }

    return this;
  };

  isSortCode = () => {
    if (this.isValid) {
      const sortCodeRegEx = /[0-9]{2}-?[0-9]{2}-?[0-9]{2}-?/;
      this.isValid = this.value && sortCodeRegEx.test(this.value);

      if (!this.isValid) {
        this.errorMessage = `The sort code you have provided is not in a valid format, please re-enter`;
      }
    }

    return this;
  };
}
