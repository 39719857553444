export default theme => ({
  container: {
    flexGrow: 1,
    position: `relative`,
  },
  paper: {
    position: `absolute`,
    zIndex: -1,
    left: 0,
    right: 0,
    opacity: 0,
    marginTop: theme.spacing.unit,
    "&.is-open": {
      zIndex: 1,
      opacity: 1,
    },
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: `wrap`,
  },
  inputInput: {
    width: `auto`,
    flexGrow: 1,
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  suggestionItem: {
    display: `flex`,
    flexDirection: `column`,
    overflow: `hidden`,
    "& strong, & span": {
      lineHeight: `1.2rem`,
    },
    "& span": {
      display: `block`,
      overflow: `hidden`,
      whiteSpace: `nowrap`,
      textOverflow: `ellipsis`,
      fontSize: `0.9rem`,
    },
  },
});
