import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { LockOpen as LockOpenIcon } from "@material-ui/icons";

import * as routes from "../../constants/routes";
import absoluteUrl from "../../_lib/absolute-url";

import Header from "../header";

export const LandingView = ({ classes }) => (
  <div className={classes.body}>
    <Header />
    <div className={classes.background}>
      <div className={classes.logo} />
      <div className={classes.poweredBy} />
      <Button
        className={classes.cta}
        to={routes.CASE_DETAILS_PAGE}
        component={Link}
        onClick={() => {
          window.ga(`send`, `pageview`, absoluteUrl(routes.CASE_DETAILS_PAGE));
        }}
      >
        <LockOpenIcon />
        Check an account
      </Button>
    </div>
  </div>
);

LandingView.propTypes = {
  classes: PropTypes.object.isRequired,
};
