import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  container: {
    padding: 2 * theme.spacing.unit,
    paddingBottom: 100 * theme.spacing.unit,
  },
  toc: {
    ...theme.typography.default,
    "& a": {
      ...theme.typography.default,
      display: `block`,
      margin: `1em 0`,
      color: `#00aae5`,
    },
  },
  h1: {
    ...theme.typography.default,
    marginBottom: `${theme.spacing.unit * 4}px`,
    fontSize: `1.2rem`,
    fontWeight: `bold`,
  },
  question: {
    ...theme.typography.default,
    marginBottom: `${theme.spacing.unit}px`,
    marginTop: `${theme.spacing.unit * 3}px`,
    "& > h1": {
      ...theme.typography.default,
      marginTop: `-${theme.spacing.unit * 1}px`,
      paddingTop: `${theme.spacing.unit * 1}px`,
      fontWeight: `bold`,
      fontSize: `1rem`,
    },
  },
  answer: {
    ...theme.typography.default,
    "& p > a": {
      ...theme.typography.default,
    },
    "& p": {
      marginBottom: `${theme.spacing.unit * 2}px`,
    },
    "& ul": {
      listStyleType: `decimal`,
    },
    "& li": {
      ...theme.typography.body2,
    },
  },
});
