/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  Modal as MUIModal,
  Typography,
} from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';

export const Modal = ({
  buttonComponent: Button,
  classes,
  content,
  isOpen,
  onClose,
  paragraphs,
  title,
}) => (
  <MUIModal open={isOpen} onClose={onClose} onEscapeKeyDown={onClose}>
    <div className={classes.paperModal}>
      <ButtonBase aria-label="Close Modal" className={classes.closeModalButton} onClick={onClose}>
        <CancelIcon color="primary" fontSize="large" />
      </ButtonBase>
      <Typography variant="h3" className={classes.headingModal}>{title}</Typography>
      <div className={classes.containerModal}>
        { paragraphs.length > 0 && paragraphs.map(paragraph => (
          <Typography key={paragraph} className={classes.paragraphModal}>
            {paragraph}
          </Typography>
        ))}
        { !!content && (
          <div dangerouslySetInnerHTML={{ _html: content }} />
        ) }
      </div>
      <Button />
    </div>
  </MUIModal>
);

Modal.propTypes = {
  buttonComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  classes: PropTypes.object.isRequired,
  content: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paragraphs: PropTypes.array,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  content: '',
  paragraphs: [],
};
