import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  container: {
    padding: 3 * theme.spacing.unit,
    paddingBottom: 2 * theme.spacing.unit,
  },
  resultHeading: {
    color: `#0c2b83`,
    fontSize: 24,
    fontWeight: `bold`,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  intro: {
    marginTop: theme.spacing.unit,
  },
  nextButtonContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 2 * theme.spacing.unit,
  },
  privacyPolicy: {
    fontSize: `.7rem`,
    position: `fixed`,
    bottom: theme.spacing.unit,
    left: theme.spacing.unit,
  },
  modalButtonContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 2 * theme.spacing.unit,
  },
  formControlWithTooltip: {
    "& input": {
      marginTop: theme.spacing.unit * 0.5,
    },
  },
  inputLabel: {
    position: `relative`,
    height: `30px`,
    display: `flex`,
    alignItems: `center`,
    "& > label": {
      position: `relative`,
      display: `flex`,
      alignItems: `center`,
    },
  },
  inputBox: {
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    "& input": {
      width: `100%`,
      marginTop: 0,
    },
    "& .validation": {
      position: `absolute`,
      right: theme.spacing.unit,
      top: 12,
    },
  },
  inputErrorMessage: {
    color: `#f44336`,
  },
  formLabel: {
    ...theme.typography.body2,
    margin: 0,
    fontSize: `0.9rem`,
  },
  formLabelWithTooltip: {
    ...theme.typography.body2,
    margin: 0,
    fontSize: `0.9rem`,
  },
});
