export const SET_FIRM_FIELD = `ACCOUNT_DETAILS::SET_FIRM_FIELD`;
export const SET_LAW_FIRMS_LIST = `ACCOUNT_DETAILS::SET_LAW_FIRMS_LIST`;
export const SET_REMAINING_ATTEMPTS = `ACCOUNT_DETAILS::SET_REMAINING_ATTEMPTS`;
export const SUBMISSION_ERROR = `ACCOUNT_DETAILS::SUBMISSION_ERROR`;
export const SET_SUBMISSION_RESULT = `ACCOUNT_DETAILS::SET_SUBMISSION_RESULT`;
export const SUBMIT = `ACCOUNT_DETAILS::SUBMIT`;
export const SUBMIT_LAW_FIRM_SELECTION = `ACCOUNT_DETAILS::SUBMIT_LAW_FIRM_SELECTION`;
export const UPDATE_FIELD = `ACCOUNT_DETAILS::UPDATE_FIELD`;
export const FIELD_ACCOUNT_NUMBER = `accountNumber`;
export const FIELD_FIRM_ID = `firmId`;
export const FIELD_FIRM_NAME = `firmName`;
export const FIELD_POST_CODE = `postCode`;
export const FIELD_SORT_CODE = `sortCode`;
export const FIELD_TNC = `TERMS_AND_CONDITIONS`;

export const SEARCH_LAW_FIRM = `SEARCH_LAW_FIRM`;
export const RESET_LAW_FIRM_RESULTS = `RESET_LAW_FIRM_RESULTS`;
export const SELECT_LAW_FIRM = `SELECT_LAW_FIRM`;
export const RESET_SELECTED_LAW_FIRM = `RESET_SELECTED_LAW_FIRM`;
