import { createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import { persistStore } from "redux-persist";

import createReducers from "../reducers";
import sagas from "../sagas";
import { createMiddleware, sagaMiddleware } from "../middlewares";

import fetchIntercept from "./fetch-intercept";

export default function register() {
  /* eslint-disable no-underscore-dangle */
  /* istanbul ignore next */
  const composeEnhancers = process.env.NODE_ENV === `development` && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
  /* eslint-enable */

  const history = createBrowserHistory({
    basename: window.config.basePath || `/`,
  });

  const middleware = createMiddleware(history);
  const reducers = createReducers(history);

  const store = createStore(reducers, composeEnhancers(middleware));

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  fetchIntercept(store.dispatch);

  return { history, store, persistor };
}
