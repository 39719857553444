import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  container: {
    padding: 3 * theme.spacing.unit,
  },
  textField: {
    marginTop: theme.spacing.unit,
  },
  nextButtonContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 2 * theme.spacing.unit,
  },
});
