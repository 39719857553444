import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import style from "./BankAccountResultView.style";
import {
  mapStateToProps,
  mapDispatchToProps,
  BankAccountResultView,
} from "./BankAccountResultView";

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(style),
)(BankAccountResultView);
