export default theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: `#0c2b83`,
    boxShadow: theme.shadows[1],
    fontSize: `0.8em`,
  },
  tooltipIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
    boxShadow: `none`,
    color: `#3871b1`,
    "&:hover": {
      cursor: `pointer`,
    },
  },
});
