/* eslint jsx-a11y/anchor-is-valid:off */
import React from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import absoluteUrl from "../../_lib/absolute-url";

import Header from "../header";
import Footer from "../footer";

const toc = [
  {
    hash: `what-is-lms-bank-account-check`,
    text: `What is LMS Bank Account Check?`,
  },
  {
    hash: `who-is-lms`,
    text: `Who is LMS?`,
  },
  {
    hash: `what-should-i-do-if-i-receive-an-email-from-my-law-firm`,
    text: `What should I do if I receive an email from my law firm informing me of a change to their
    bank details?`,
  },
  {
    hash: `how-can-i-spot-a-scam-email`,
    text: `How can I spot a scam email?`,
  },
  {
    hash: `what-if-my-case-cant-be-found`,
    text: `What if my case can’t be found?`,
  },
  {
    hash: `what-if-i-dont-receive-the-email`,
    text: `What if I don’t receive the email?`,
  },
  {
    hash: `attempts-remaining-limit-reached`,
    text: `Attempts remaining / limit reached`,
  },
  {
    hash: `what-if-my-case-cant-be-found`,
    text: `What if my law firm can’t be found?`,
  },
  {
    hash: `what-if-my-link-expired`,
    text: `What if my link expires?`,
  },
  {
    hash: `what-if-the-bank-details-dont-match`,
    text: `What if the bank details don’t match? Should I transfer my money?`,
  },
];

export const FAQ = ({ classes }) => (
  <div className={classes.body}>
    <Header />
    <section className={classes.contentContainer}>
      <div className={classes.container}>
        <Typography className={classes.h1}>Frequently Asked Questions</Typography>
        <section className={classes.toc}>
          {toc.map(({ hash, text }) => (
            <Link
              key={hash}
              to={`/faq#${hash}`}
              component={HashLink}
              onClick={() => {
                window.ga(`send`, `pageview`, absoluteUrl(`faq#${hash}`));
              }}
            >
              {text}
            </Link>
          ))}
        </section>
        <Typography id="what-is-lms-bank-account-check" className={classes.question}>
          <h1>What is LMS Bank Account Check?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            LMS Bank Account Check is a fraud prevention service which allows you to check law firm
            bank details before sending them any money. This service is provided by LMS, you can
            find out more below.
          </Typography>
          <Typography>
            If you have any concernsabout any communications you may have received from any parties
            visit the HM Government fraud prevention advice page at&nbsp;
            <a href="https://takefive-stopfraud.org.uk/" target="_blank" rel="noopener noreferrer">
              https://takefive-stopfraud.org.uk/
            </a>
            .
          </Typography>
        </div>
        <Typography id="who-is-lms" className={classes.question}>
          <h1>Who is LMS?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            LMS is the UK’s leading provider of conveyancing services and has over 25 years’
            experience. We deliver the highest quality conveyancing along with management of wider
            transactional panels to lenders of all sizes, mortgage intermediaries and estate agents.
            LMS successfully manages over 300,000 conveyancing transactions a year.
          </Typography>
          <Typography>
            LMS is the trading name for Legal Marketing Services Limited (03002073), Bickerton
            House, Lloyd Drive, Ellesmere Port, CH65 9HQ.
          </Typography>
        </div>
        <Typography
          id="what-should-i-do-if-i-receive-an-email-from-my-law-firm"
          className={classes.question}
        >
          <h1>
            What should I do if I receive an email from my law firm informing me of a change to
            their bank details?
          </h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            Most law firms will not change their bank details during the time that they work on your
            transaction. In the unlikely event they did change their bank details you should not be
            informed via email.
          </Typography>
          <Typography>
            Fraudsters do not just try and contact you by phone and text, they also ‘phish’,
            contacting you by email too. So always be suspicious of unsolicited emails that are
            supposedly from your bank or some other trusted organisation because the address can
            easily be faked. Never automatically click on any links they contain either, not before
            stopping to check if they seem genuine first.
          </Typography>
        </div>
        <Typography id="how-can-i-spot-a-scam-email" className={classes.question}>
          <h1>How can I spot a scam email?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>Take Five provides the following advice:</Typography>
          <ul>
            <li>
              The sender’s address doesn’t match the website address of the organisation it says
              it’s from. Roll your mouse pointer over the sender’s name to reveal its true address.
            </li>
            <li>
              The email doesn’t use your proper name – using something like “Dear customer” instead.
            </li>
            <li>There’s a sense of urgency, asking you to act immediately.</li>
            <li>
              There’s a prominent website link which may seem like the proper address, but with one
              character different.
            </li>
            <li>There’s a request for personal information.</li>
            <li>There are spelling and grammatical errors.</li>
            <li>
              The entire text of the email is within an image rather than the usual text format and
              the image contains an embedded hyperlink to a bogus site. Again roll your mouse
              pointer over the link to reveal its true destination.
            </li>
          </ul>
        </div>
        <Typography id="what-if-my-case-cant-be-found" className={classes.question}>
          <h1>What if my case can’t be found?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            If we have been unable to match your surname and postcode to our records of customers
            who are currently remortgaging or purchasing a property through a lender managed by LMS,
            please ensure the surname and postcode match those on the mortgage offer sent to you by
            the lender and try again.
          </Typography>
          <Typography>
            It may be that we do not have your details which unfortunately means that this service
            is unavailable to you. Please speak to your law firm directly to check their bank
            details.
          </Typography>
        </div>
        <Typography id="what-if-i-dont-receive-the-email" className={classes.question}>
          <h1>What if I don’t receive the email?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            If you have not received your email within a few minutes, please check your junk or spam
            folders first and if the email is not there re-input your details again ensuring the
            email address is correct.
          </Typography>
        </div>
        <Typography id="attempts-remaining-limit-reached" className={classes.question}>
          <h1>Attempts remaining / limit reached</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            Each link is valid for up to 72 hours and a maximum of 3 checks per link. Attempts
            remaining will let you know how many checks you have left.
          </Typography>
        </div>
        <Typography id="what-if-my-law-firm-cant-be-found" className={classes.question}>
          <h1>What if my law firm can’t be found?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            LMS Bank Account Check covers law firms that fulfil 97% of conveyancing law firms
            transactions in the UK, however, sometimes a law firms bank details may not be available
            to check against. Please speak to your law firm directly to check their bank details.
          </Typography>
        </div>
        <Typography id="what-if-my-link-expired" className={classes.question}>
          <h1>What if my link expired?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            If your link has expired, you will need to go to{` `}
            <a href="https://lms.com/bankaccountcheck/">lms.com/bankaccountcheck</a> to obtain a new
            link. You will be asked to enter your email address, surname and postcode.
          </Typography>
        </div>
        <Typography id="what-if-the-bank-details-dont-match" className={classes.question}>
          <h1>What if the bank details don’t match? Should I transfer my money?</h1>
        </Typography>
        <div className={classes.answer}>
          <Typography>
            If the details you have provided do not match the records that we hold for the law firm
            should speak to your contact at the law firm by phone before transferring money.
          </Typography>
          <Typography>
            If you are unsure of the contact details for the law firm you can find them via the law
            firms regulator.
          </Typography>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};
