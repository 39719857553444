import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  ACCOUNT_DETAILS_PAGE,
  CASE_DETAILS_PAGE,
  ERROR_PAGE,
  LANDING_PAGE,
  LAW_FIRM_SEARCH_PAGE,
  BANK_ACCOUNT_RESULT_PAGE,
  FAQ_PAGE,
} from "./constants/routes";

import registerStore from "./config/store";
import theme from "./config/theme";

import CaseDetailsView from "./components/case-details-view";
import ErrorView from "./components/error-view";
import AccountDetailsView from "./components/account-details-view";
import LawFirmSearchView from "./components/law-firm-search-view";
import LandingView from "./components/landing-view";
import BankAccountResultView from "./components/bank-account-result-view";
import FAQView from "./components/faq-view";

function App() {
  const { store, history, persistor } = registerStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path={LANDING_PAGE} component={LandingView} />
              <Route exact path={CASE_DETAILS_PAGE} component={CaseDetailsView} />
              <Route exact path={ERROR_PAGE} component={ErrorView} />
              <Route exact path={ACCOUNT_DETAILS_PAGE} component={AccountDetailsView} />
              <Route exact path={LAW_FIRM_SEARCH_PAGE} component={LawFirmSearchView} />
              <Route exact path={BANK_ACCOUNT_RESULT_PAGE} component={BankAccountResultView} />
              <Route exact path={FAQ_PAGE} component={FAQView} />
              <Route path="*" render={() => <ErrorView status={403} />} />
            </Switch>
          </ConnectedRouter>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
}

App.displayName = App.name;

export default App;
