import { schema } from 'normalizr';

const lawFirm = new schema.Entity('lawFirm', {}, {
  processStrategy: entity => ({
    ...entity,
    label: entity.title,
  }),
});

export default lawFirm;
