import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as R from "ramda";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  InputBase,
} from "@material-ui/core";
import { submit } from "../../actions/account-details";

import * as routes from "../../constants/routes";
import absoluteUrl from "../../_lib/absolute-url";
import trimValue from "../../_lib/trim-value";

import Header from "../header";
import Footer from "../footer";
import AttemptsRemaining from "../attempts-remaining";
import Loading from "../loading";

function isValid(field) {
  const validators = {
    accountNumber: value => value.length < 1 || /[0-9]{8}/.test(value),
    sortCode: value => value.length < 1 || /[0-9]{2}-?[0-9]{2}-?[0-9]{2}-?/.test(value),
    tnc: value => typeof value === `boolean` && value,
  };
  return validators[field];
}

function formatValue(field) {
  const formatters = {
    accountNumber: value => trimValue(value)
      .replace(/[^0-9]/, ``)
      .substr(0, 8),
    sortCode: value => (trimValue(value).length > 0
      ? trimValue(value)
        .replace(/[^0-9]/, ``)
        .match(/\d{1,2}/g)
        .slice(0, 3)
        .join(`-`)
      : ``),
    tnc: value => value,
  };
  return formatters[field];
}

export class AccountDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumber: ``,
      sortCode: ``,
      tnc: false,
      isValid: {
        accountNumber: true,
        sortCode: true,
        tnc: true,
      },
    };
  }

  onChangeInput = field => (ev) => {
    let { value } = ev.target;
    if (field === `tnc`) {
      value = ev.target.checked;
    }
    this.setState({
      [field]: formatValue(field)(value),
    });
  };

  onBlurInput = field => () => {
    this.setState(prevState => R.assocPath([`isValid`, field], isValid(field)(prevState[field]), prevState));
  };

  onFocusInput = field => () => {
    this.setState(prevState => R.assocPath([`isValid`, field], true, prevState));
  };

  onClickSubmit = (ev) => {
    ev.preventDefault();
    window.ga(`send`, `pageview`, absoluteUrl(routes.BANK_ACCOUNT_RESULT_PAGE));
    const { accountNumber, sortCode, tnc } = this.state;
    this.props.submit({ accountNumber, sortCode, tnc });
  };

  render() {
    const { classes, appIsLoading } = this.props;
    const { accountNumber, sortCode, tnc } = this.state;

    const areFieldsValid = [`accountNumber`, `sortCode`, `tnc`].reduce(
      (acc, field) => acc && isValid(field)(this.state[field]),
      true,
    );

    return (
      <div className={classes.body}>
        <Header />
        <section className={classes.contentContainer}>
          <div className={`${classes.container} form-container`}>
            {appIsLoading && <Loading />}
            <Typography variant="h3" align="center">
              Please enter the account details the law firm have provided
            </Typography>
            <form method="POST">
              <FormControl variant="outlined" error={!this.state.isValid.accountNumber} fullWidth>
                <InputLabel shrink htmlFor="accountNumber" className={classes.formLabel}>
                  Law firm account number
                </InputLabel>
                <InputBase
                  id="accountNumber"
                  value={accountNumber}
                  onChange={this.onChangeInput(`accountNumber`)}
                  onBlur={this.onBlurInput(`accountNumber`)}
                  onFocus={this.onFocusInput(`accountNumber`)}
                />
                <FormHelperText error={!this.state.isValid.accountNumber}>
                  {!this.state.isValid.accountNumber
                    ? `The account number you have provided is not in a valid format, please re-enter`
                    : ``}
                </FormHelperText>
              </FormControl>

              <FormControl variant="outlined" error={!this.state.isValid.sortCode} fullWidth>
                <InputLabel shrink htmlFor="sortCode" className={classes.formLabel}>
                  Law firm sort code
                </InputLabel>
                <InputBase
                  id="sortCode"
                  value={sortCode.replace(/(\d{2})(\d{2})(\d{2})/, `$1-$2-$3`)}
                  onChange={this.onChangeInput(`sortCode`)}
                  onBlur={this.onBlurInput(`sortCode`)}
                  onFocus={this.onFocusInput(`sortCode`)}
                />
                <FormHelperText error={!this.state.isValid.sortCode}>
                  {!this.state.isValid.sortCode
                    ? `The sort code you have provided is not in a
                valid format, please re-enter`
                    : ``}
                </FormHelperText>
              </FormControl>

              <Typography>
                This service is provided by LMS.com to support safer conveyancing.
              </Typography>

              <div>
                <Link
                  href={`${process.env.PUBLIC_URL}${window.config.termsOfUsePath}`}
                  target="_blank"
                  className={classes.link}
                >
                  Terms &amp; conditions of use
                </Link>
              </div>
              <div>
                <Link
                  href={`${process.env.PUBLIC_URL}${window.config.privacyNoticePath}`}
                  target="_blank"
                  className={classes.link}
                >
                  Privacy policy
                </Link>
              </div>

              <FormControlLabel
                control={(
                  <Checkbox
                    id="tnc"
                    checked={tnc}
                    onChange={this.onChangeInput(`tnc`)}
                    color="primary"
                  />
)}
                label="I accept the Terms &amp; Conditions of use"
              />
              <FormHelperText error>
                {!isValid(`tnc`)(tnc)
                  ? `Without agreeing to the Terms &amp; Conditions you are unable to use LMS Bank Account
              Check`
                  : ``}
              </FormHelperText>

              <div className={classes.nextButtonContainer}>
                <Button
                  data-submit-form
                  type="submit"
                  variant="contained"
                  color={areFieldsValid ? `primary` : `default`}
                  onClick={this.onClickSubmit}
                  fullWidth
                  disabled={!areFieldsValid}
                >
                  Submit
                </Button>
              </div>
            </form>

            <AttemptsRemaining />
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

AccountDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  appIsLoading: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({ app }) => ({
  appIsLoading: app.isLoading,
});

export const mapDispatchToProps = dispatch => ({
  submit: bindActionCreators(submit, dispatch),
});
