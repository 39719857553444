import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  header: {
    padding: `${2 * theme.spacing.unit}px ${4 * theme.spacing.unit}px`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  h2: {
    color: theme.palette.primary.contrastText,
    fontSize: `1rem`,
    fontWeight: `bold`,
  },
  container: {
    padding: `${2 * theme.spacing.unit}px ${4 * theme.spacing.unit}px`,
  },
  inputSearchPostcode: {
    '& input[type="text"]': {
      width: `300px`,
    },
    "& button": {
      width: `100px`,
    },
  },
  nextButtonContainer: {
    alignItems: `center`,
    display: `flex`,
    justifyContent: `center`,
    marginTop: 2 * theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  autoCompleteContainer: {
    "& [role=listbox] > div > div:last-of-type": {
      color: `#00aae5`,
      textDecoration: `underline`,
    },
  },
  messageLawFirmNotListed: {
    marginTop: theme.spacing.unit,
    "& > p": {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});
