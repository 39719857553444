import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { bindActionCreators } from "redux";
import Downshift from "downshift";
import {
  Button,
  FormControl,
  FormHelperText,
  InputBase,
  InputAdornment,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon, ChevronRight as ChevronRightIcon } from "@material-ui/icons";

import * as routes from "../../constants/routes";
import absoluteUrl from "../../_lib/absolute-url";
import trimValue from "../../_lib/trim-value";

import {
  searchLawFirm,
  resetLawFirmResults,
  selectLawFirm,
  resetSelectedLawFirm,
  submitLawFirmSelection,
} from "../../actions/account-details";

import { isPostCode } from "../../services/validator";

import Header from "../header";
import Footer from "../footer";
import AttemptsRemaining from "../attempts-remaining";
import Autocomplete from "../autocomplete";
import Loading from "../loading";

import SelectedLawFirm from "./SelectedLawFirm";

// prepareSuggestionsList :: LawFirm a => [a] -> [a]
function prepareSuggestionsList(lawFirmsList = {}) {
  return R.values(lawFirmsList)
    .slice(0, 7)
    .concat({
      id: -1,
      label: `My law firm isn't listed here`,
    });
}

function validatePostCode(postcode) {
  return postcode.length > 2 && isPostCode(postcode);
}

function downshiftStateReducer(state, changes) {
  switch (changes.type) {
    // don't re-send the selected item when the user clicks out from AutoComplete
    case Downshift.stateChangeTypes.mouseUp:
      return state;
    // don't close the suggestions list when user blurs out of input (ex. changes browser tab)
    case Downshift.stateChangeTypes.blurInput:
      return state;
    default:
      return changes;
  }
}

export class LawFirmSearchView extends Component {
  constructor(props) {
    super(props);

    const { selectedLawFirm } = this.props;
    this.state = {
      postCode: R.propOr(``, `postcode`, selectedLawFirm),
      firmName: R.propOr(``, `label`, selectedLawFirm),
      selectedFirmId: R.prop(`id`, selectedLawFirm),
      isValidPostCode: selectedLawFirm || false,
      showLawFirmSearch: Boolean(R.propOr(false, `label`, selectedLawFirm)),
      isLawFirmNotListed: false,
    };
  }

  onChangePostCode = (ev) => {
    const { value = `` } = ev.target;
    const postCode = trimValue(value);
    this.setState({
      postCode: postCode.toUpperCase(),
      isValidPostCode: true,
    });
  };

  onBlurPostCode = () => {
    const { postCode, showLawFirmSearch } = this.state;
    this.props.resetLawFirmResults();
    this.props.resetSelectedLawFirm();
    this.setFirmNotListed(false);
    const isValidPostCode = validatePostCode(postCode);
    // only change if the postCode turns invalid
    const newShowLawFirmSearch = !isValidPostCode ? false : showLawFirmSearch;
    this.setState({
      isValidPostCode,
      showLawFirmSearch: newShowLawFirmSearch,
    });
  };

  onKeyDownPostCode = (ev) => {
    if (ev.keyCode === 13) {
      // prevent IE11 from submitting the form onKeyDown:Enter
      ev.preventDefault();
      // make onKeyDown:Enter submit the form on all browsers
      this.onBlurPostCode();
      this.onClickShowLawFirmSearch();
    }
  };

  onClickShowLawFirmSearch = () => {
    const { postCode, isValidPostCode } = this.state;
    if (postCode.length > 0 && isValidPostCode) {
      this.setState({ showLawFirmSearch: true });
    }
  };

  onChangeFirmName = (value = ``) => {
    const firmName = trimValue(value);
    // when changing the input, make sure there's a real change
    if (this.state.firmName === firmName) return;
    this.props.resetSelectedLawFirm();
    if (firmName.length === 0) {
      this.setState({ isLawFirmNotListed: false, firmName });
      return;
    }
    this.setState({ firmName });
    if (firmName.length < 3) return;
    const { postCode } = this.state;
    this.props.searchLawFirm(postCode, firmName);
  };

  onSelectLawFirm = (selectedFirmId) => {
    // happens when clicking outside the suggestion link without selecting anything
    if (typeof selectedFirmId === `undefined`) {
      return;
    }
    // selected: My law firm isn't listed here
    if (selectedFirmId === -1) {
      this.setFirmNotListed(true);
      this.setState({ isLawFirmNotListed: true });
      this.props.resetLawFirmResults();
      this.props.resetSelectedLawFirm();
      return;
    }

    this.setFirmNotListed(false);
    const { lawFirmsList } = this.props;
    const lawFirm = lawFirmsList[selectedFirmId];
    this.props.selectLawFirm(lawFirm);
    this.setState({ selectedFirmId, firmName: lawFirm.label });
  };

  setFirmNotListed = (isLawFirmNotListed) => {
    this.props.resetLawFirmResults();
    this.props.resetSelectedLawFirm();
    this.setState({ isLawFirmNotListed, firmName: `` });
  };

  onClickSubmit = (ev) => {
    ev.preventDefault();
    window.ga(`send`, `pageview`, absoluteUrl(routes.ACCOUNT_DETAILS_PAGE));
    this.props.submitLawFirmSelection();
  };

  downshiftItemToString = (itemId) => {
    const { lawFirmsList } = this.props;
    const { firmName } = this.state;
    return itemId in lawFirmsList ? lawFirmsList[itemId].label : firmName;
  };

  render() {
    const {
      classes, lawFirmsList, selectedLawFirm, appIsLoading,
    } = this.props;
    const {
      isValidPostCode,
      postCode,
      showLawFirmSearch,
      selectedFirmId,
      isLawFirmNotListed,
      firmName,
    } = this.state;

    return (
      <div className={classes.body}>
        <Header />
        <section className={classes.contentContainer}>
          <div className="form-container">
            {appIsLoading && <Loading />}
            <section className={classes.header}>
              <Typography variant="h2" className={classes.h2}>
                Welcome back
              </Typography>
            </section>
            <section className={classes.container}>
              <form method="post">
                <FormControl error={postCode.length > 0 && !isValidPostCode} fullWidth>
                  <InputLabel shrink htmlFor="adornment-password" className={classes.formLabel}>
                    Please provide the law firms postcode:
                  </InputLabel>
                  <InputBase
                    id="adornment-password"
                    className={classes.inputSearchPostcode}
                    onChange={this.onChangePostCode}
                    onBlur={this.onBlurPostCode}
                    value={postCode}
                    onKeyDown={this.onKeyDownPostCode}
                    autoComplete="off"
                    endAdornment={(
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          aria-label="Search"
                          onClick={this.onClickShowLawFirmSearch}
                          color={postCode.length > 0 && isValidPostCode ? `primary` : `default`}
                          disabled={postCode.length > 0 && !isValidPostCode}
                        >
                          Search
                          <SearchIcon className={classes.rightIcon} />
                        </Button>
                      </InputAdornment>
)}
                  />
                  <FormHelperText error={postCode.length > 0 && !isValidPostCode}>
                    {postCode.length > 0 && !isValidPostCode
                      ? `The postcode you have provided is not in a valid format, please re-enter your
                        postcode`
                      : ``}
                  </FormHelperText>
                </FormControl>
                {showLawFirmSearch && (
                  <div className={classes.autoCompleteContainer}>
                    <Autocomplete
                      initialInputValue={firmName}
                      suggestions={prepareSuggestionsList(lawFirmsList)}
                      onChange={this.onChangeFirmName}
                      onSelect={this.onSelectLawFirm}
                      itemToString={this.downshiftItemToString}
                      label="Your Law Firm’s Name:"
                      stateReducer={downshiftStateReducer}
                    />
                    <FormHelperText error={selectedFirmId && selectedFirmId === -2}>
                      {selectedFirmId === -2 && `We need this information to proceed`}
                    </FormHelperText>
                  </div>
                )}
                <SelectedLawFirm
                  classes={classes}
                  selectedLawFirm={selectedLawFirm}
                  isLawFirmNotListed={isLawFirmNotListed}
                />
                <div className={classes.nextButtonContainer}>
                  <Button
                    data-submit-form
                    type="submit"
                    variant="contained"
                    color={selectedLawFirm ? `primary` : `default`}
                    onClick={this.onClickSubmit}
                    fullWidth
                    disabled={!selectedLawFirm}
                  >
                    Next
                    <ChevronRightIcon className={classes.rightIcon} />
                  </Button>
                </div>
              </form>

              <AttemptsRemaining />
            </section>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

LawFirmSearchView.propTypes = {
  classes: PropTypes.object.isRequired,
  lawFirmsList: PropTypes.object.isRequired,
  searchLawFirm: PropTypes.func.isRequired,
  resetLawFirmResults: PropTypes.func.isRequired,
  selectLawFirm: PropTypes.func.isRequired,
  selectedLawFirm: PropTypes.object,
  resetSelectedLawFirm: PropTypes.func.isRequired,
  submitLawFirmSelection: PropTypes.func.isRequired,
  appIsLoading: PropTypes.bool.isRequired,
};

LawFirmSearchView.defaultProps = {
  selectedLawFirm: null,
};

export const mapStateToProps = ({ accountDetails: { lawFirmsList, selectedLawFirm }, app }) => ({
  lawFirmsList,
  selectedLawFirm,
  appIsLoading: app.isLoading,
});

export const mapDispatchToProps = dispatch => ({
  searchLawFirm: bindActionCreators(searchLawFirm, dispatch),
  resetLawFirmResults: bindActionCreators(resetLawFirmResults, dispatch),
  selectLawFirm: bindActionCreators(selectLawFirm, dispatch),
  resetSelectedLawFirm: bindActionCreators(resetSelectedLawFirm, dispatch),
  submitLawFirmSelection: bindActionCreators(submitLawFirmSelection, dispatch),
});
