export const UPDATE_FIELD = `CASE_DETAILS::UPDATE_FIELD`;
export const UPDATE_FIELD_VALIDATION = `CASE_DETAILS::UPDATE_FIELD_VALIDATION`;
export const RESET_SUBMISSION = `CASE_DETAILS::RESET_SUBMISSION`;
export const SUBMIT = `CASE_DETAILS::SUBMIT`;
export const SUBMIT_SUCCESS = `CASE_DETAILS::SUBMIT_SUCCESS`;
export const SUBMIT_FAILURE = `CASE_DETAILS::SUBMIT_FAILURE`;
export const FIELD_EMAIL = `email`;
export const FIELD_SURNAME = `surname`;
export const FIELD_POSTCODE = `postcode`;
export const FIELD_RECAPTCHA = `recaptcha`;
