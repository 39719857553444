import { all } from 'redux-saga/effects';

import accountDetails from './account-details';
import app from './app';
import caseDetails from './case-details';
import session from './session';

const sagas = [
  accountDetails,
  app,
  caseDetails,
  session,
];

function* rootSaga() {
  yield all(sagas);
}

export default rootSaga;
