import {
  call, delay, fork, put, select, takeLatest,
} from "redux-saga/effects";
import { push } from "connected-react-router";
import { normalize } from "normalizr";

import { SUBMIT_LAW_FIRM_SELECTION, SUBMIT, SEARCH_LAW_FIRM } from "../constants/account-details";
import http from "../services/http";
import * as API_ENDPOINTS from "../constants/api-endpoints";
import { SESSION_EMAIL_TOKEN } from "../constants/session";
import { BANK_ACCOUNT_RESULT_PAGE, ACCOUNT_DETAILS_PAGE } from "../constants/routes";
import { apiError, appIsLoading } from "../actions/app";
import {
  setSubmissionResult,
  setSubmissionError,
  setLawFirmsList,
  setRemainingAttempts,
  resetLawFirmResults,
} from "../actions/account-details";
import lawFirmSchema from "../schemas/law-firm";

export const getVerification = ({
  token, branchId, accountNumber, sortCode,
}) => {
  const url = `${
    API_ENDPOINTS.CLIENT_ACCOUNT_VERIFICATION
  }/${branchId}/${accountNumber}/${sortCode}`;
  return http
    .get(
      url,
      {},
      {
        headers: {
          [SESSION_EMAIL_TOKEN]: token,
        },
      },
      {
        ignoreErrorStatusCodes: /404/,
      },
    )
    .then(async response => ({
      data: await response.json(),
      status: response.status,
    }));
};

export const getLawFirms = ({ firmName, postCode, token }) => {
  const url = `${API_ENDPOINTS.LAW_FIRMS}/${firmName}/${postCode}`;
  return http
    .get(
      url,
      {},
      {
        headers: {
          [SESSION_EMAIL_TOKEN]: token,
        },
      },
    )
    .then(response => response.json());
};

export const getRemainingAttempts = ({ accountDetails: { attempts } }) => attempts;
export const getBranchId = state => state.accountDetails.selectedLawFirm.id;
export const getSessionStore = ({ session }) => session;

export function* submit({ bankDetails }) {
  try {
    yield put(appIsLoading(true));
    yield delay(500);
    const { [SESSION_EMAIL_TOKEN]: token } = yield select(getSessionStore);
    const { accountNumber, sortCode } = bankDetails;
    const branchId = yield select(getBranchId);
    const { status, data } = yield call(getVerification, {
      token,
      branchId,
      accountNumber,
      sortCode: sortCode.replace(/-/gm, ``),
    });
    yield put(setSubmissionResult({ status, ...data }));

    const remainingAttempts = yield select(getRemainingAttempts);
    yield put(setRemainingAttempts({ remainingAttempts: remainingAttempts - 1 }));

    yield put(push(BANK_ACCOUNT_RESULT_PAGE));
  } catch (e) {
    yield put(setSubmissionError(e));
    if (e instanceof http.HTTPError) {
      yield put(apiError(e));
    }
  } finally {
    yield put(appIsLoading(false));
  }
}

export function* searchLawFirm({ postCode, firmName }) {
  const { [SESSION_EMAIL_TOKEN]: token } = yield select(getSessionStore);

  try {
    const response = yield call(getLawFirms, {
      firmName,
      postCode,
      token,
    });

    const {
      entities: { lawFirm: lawFirmsList },
    } = normalize(response, [lawFirmSchema]);

    yield put(setLawFirmsList(lawFirmsList || {}));
  } catch (error) {
    yield put(resetLawFirmResults());
    if (error instanceof http.HTTPError) {
      yield put(apiError(error));
    }
  }
}

export function* goToAccountDetailsPage() {
  yield put(appIsLoading(true));
  yield delay(500);
  yield put(push(ACCOUNT_DETAILS_PAGE));
  yield put(appIsLoading(false));
}

export function* watchActions() {
  yield takeLatest(SEARCH_LAW_FIRM, searchLawFirm);
  yield takeLatest(SUBMIT_LAW_FIRM_SELECTION, goToAccountDetailsPage);
  yield takeLatest(SUBMIT, submit);
}

export default fork(watchActions);
