import {
  call, fork, put, select, takeLatest, delay,
} from "redux-saga/effects";
import { values } from "ramda";

import http from "../services/http";
import {
  SUBMIT,
  FIELD_EMAIL,
  FIELD_SURNAME,
  FIELD_POSTCODE,
  FIELD_RECAPTCHA,
} from "../constants/case-details";
import * as API_ENDPOINTS from "../constants/api-endpoints";
import { submitSuccess, submitFailure } from "../actions/case-details";
import { appIsLoading } from "../actions/app";

export const postVerification = (body, recaptcha) => http.post(
  API_ENDPOINTS.VERIFICATION,
  { body, headers: { recaptcha } },
  { ignoreErrorStatusCodes: /404/ },
);

export const getFields = ({ caseDetails: { fields } }) => fields;

export function* submit() {
  try {
    yield put(appIsLoading(true));
    yield delay(500);
    const fields = yield select(getFields);
    const { value: recaptcha } = fields[FIELD_RECAPTCHA];

    if (values(fields).every(field => field.isValid)) {
      const response = yield call(
        postVerification,
        {
          [FIELD_EMAIL]: fields[FIELD_EMAIL].value,
          [FIELD_SURNAME]: fields[FIELD_SURNAME].value,
          [FIELD_POSTCODE]: fields[FIELD_POSTCODE].value,
        },
        recaptcha,
      );

      if (response.status >= 200 && response.status < 300) {
        yield put(submitSuccess());
      } else {
        throw response.status;
      }
    }
  } catch (e) {
    yield put(submitFailure(e));
  } finally {
    yield put(appIsLoading(false));
  }
}

export function* watchActions() {
  yield takeLatest(SUBMIT, submit);
}

export default fork(watchActions);
