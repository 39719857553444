import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

function matched(submissionResult) {
  const { status } = submissionResult;
  return status >= 200 && status <= 300;
}

function link(href) {
  if (/^http|^www|\.co\.uk/.test(href)) return /^http/.test(href) ? href : `https://${href}`;
  return href;
}

function ButtonExit(props) {
  const {
    className, submissionResult, resetSelected, history,
  } = props;
  return (
    <Button
      className={className}
      onClick={() => {
        resetSelected();
        if (matched(submissionResult)) {
          window.location = link(window.config.exitLink);
        } else {
          history.push(`/law-firm-search`);
        }
      }}
    >
      {matched(submissionResult) ? `Exit` : `Start Again`}
      <ExitToAppIcon />
    </Button>
  );
}

ButtonExit.propTypes = {
  className: PropTypes.string.isRequired,
  submissionResult: PropTypes.object.isRequired,
  resetSelected: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default ButtonExit;
