import {
  RESET_SUBMISSION,
  SUBMIT_FAILURE,
  SUBMIT_SUCCESS,
  SUBMIT,
  UPDATE_FIELD,
  UPDATE_FIELD_VALIDATION,
} from "../constants/case-details";

export const updateField = payload => ({
  type: UPDATE_FIELD,
  ...payload,
});

export const updateFieldValidation = payload => ({
  type: UPDATE_FIELD_VALIDATION,
  ...payload,
});

export const submit = () => ({
  type: SUBMIT,
});

export const resetSubmission = () => ({
  type: RESET_SUBMISSION,
});

export const submitSuccess = () => ({
  type: SUBMIT_SUCCESS,
});

export const submitFailure = error => ({
  type: SUBMIT_FAILURE,
  error,
});
