import { API_ERROR, APP_IS_LOADING } from "../constants/app";

export const apiError = response => ({
  type: API_ERROR,
  response,
});

export const appIsLoading = isLoading => ({
  type: APP_IS_LOADING,
  isLoading,
});
