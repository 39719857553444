import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  Link, Typography, Button, Divider,
} from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import {
  Error as ErrorIcon,
  ExitToApp as ExitToAppIcon,
  Refresh as RefreshIcon,
} from "@material-ui/icons";

import absoluteUrl from "../../_lib/absolute-url";

import {
  CASE_DETAILS_PAGE,
  ERROR_CODE_EXPIRED,
  ERROR_TOO_MANY_ATTEMPTS,
  ERROR_UNAUTHORIZED,
  ERROR_FORBIDDEN,
} from "../../constants/routes";

import Header from "../header";
import Footer from "../footer";

// errorDescription :: ErrorDescription a => Number -> a
function errorDescription(code) {
  if (code === ERROR_CODE_EXPIRED || code === ERROR_UNAUTHORIZED) {
    return {
      title: `Link expired`,
      text: `Sorry but this link has now expired, please click below to obtain a new link. You will
      be asked to enter your email address, surname and postcode.`,
    };
  }
  if (code === ERROR_TOO_MANY_ATTEMPTS) {
    return {
      title: `Limit reached`,
      text: `Sorry, you have carried out to many searches.`,
      faq: {
        text: `Why can’t I run more searches?`,
        href: `/faq#attempts-remaining-limit-reached`,
      },
    };
  }
  if (code === ERROR_FORBIDDEN) {
    return {
      title: `Bad domain`,
      text: `Sorry, the link you have used is not valid. Please go to lms.com/bankaccountcheck.`,
    };
  }
  if (code >= 500) {
    return {
      title: `Service offline`,
      text: `Sorry it’s us not you. This service is currently offline, please try again later. If
    this message persists please email helpdesk@lms.com`,
    };
  }
  return { title: `Unexpected error [${code}]`, text: `` };
}

export const ErrorView = ({ classes, status }) => {
  const { code } = queryString.parse(window.location.search, { ignoreQueryPrefix: true });

  const error = errorDescription(Number(code || status));
  return (
    <div className={classes.body}>
      <Header />
      <section className={classes.contentContainer}>
        <div className={`${classes.container} form-container`}>
          <ErrorIcon className={classes.resultIconWarning} />

          <Typography variant="h3" align="center" className={classes.resultHeading}>
            {error.title}
          </Typography>
          <Divider variant="middle" className={classes.resultHeaderDivider} />
          <Typography align="center">{error.text}</Typography>
          {error.faq && (
            <Typography align="center">
              <Link
                to={error.faq.href}
                component={HashLink}
                onClick={() => {
                  window.ga(
                    `send`,
                    `pageview`,
                    absoluteUrl(`faq#attempts-remaining-limit-reached`),
                  );
                }}
              >
                {error.faq.text}
              </Link>
            </Typography>
          )}

          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttons}
              variant="contained"
              color="secondary"
              href={`${window.config.basePath || ``}${CASE_DETAILS_PAGE}`}
            >
              Send me a new link
              <RefreshIcon />
            </Button>
            <Button
              className={classes.buttons}
              variant="contained"
              color="secondary"
              href={`${window.config.exitLink}`}
            >
              Exit
              <ExitToAppIcon />
            </Button>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

ErrorView.propTypes = {
  status: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

ErrorView.defaultProps = {
  status: -1,
};
