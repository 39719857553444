import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import app from "./app";
import accountDetails from "./account-details";
import caseDetails from "./case-details";
import session from "./session";

const createReducers = history => combineReducers({
  app,
  accountDetails: persistReducer(
    {
      key: `accountDetails`,
      storage,
      stateReconciler: autoMergeLevel2,
      whitelist: [`selectedLawFirm`, `submissionResult`],
    },
    accountDetails,
  ),
  caseDetails,
  router: connectRouter(history),
  session: persistReducer(
    {
      key: `session`,
      storage,
      stateReconciler: autoMergeLevel2,
    },
    session,
  ),
});

export default createReducers;
