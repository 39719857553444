import { applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware();

export const createMiddleware = history => applyMiddleware(
  sagaMiddleware,
  routerMiddleware(history),
);
