import React from "react";
import PropTypes from "prop-types";

function SuggestionItem({ suggestion, classes }) {
  const { label, companyName, tradingName } = suggestion;
  if (suggestion.id === -1) return label;
  return (
    <div className={classes.suggestionItem}>
      <strong>{companyName}</strong>
      <span>{tradingName}</span>
    </div>
  );
}

SuggestionItem.propTypes = {
  suggestion: PropTypes.object,
  classes: PropTypes.object,
};

SuggestionItem.defaultProps = {
  suggestion: {},
  classes: {},
};

export default SuggestionItem;
