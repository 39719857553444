import fetchIntercept from "fetch-intercept";

import * as API_ENDPOINTS from "../constants/api-endpoints";

import { apiError } from "../actions/app";

// IE11 hack: hold what URL is being requested from server
let currentUrl = null;

export default (dispatch) => {
  const onResponse = (response) => {
    // IE11 hack: response.url is always "",
    const responseUrl = response.url || currentUrl;
    // the 404 returned by these actions are handled by the app, not the http service
    const rg = new RegExp(
      [API_ENDPOINTS.CLIENT_ACCOUNT_VERIFICATION, API_ENDPOINTS.VERIFICATION].join(`|`),
    );
    if (rg.test(responseUrl) && response.status === 404) {
      return response;
    }
    if (Number(response.status) > 399) {
      dispatch(apiError(response));
    }
    return response;
  };

  const onRequest = (url, config) => {
    // IE11 hack: keep hold of what URL is being requested
    currentUrl = url;
    return [url, config];
  };

  fetchIntercept.register({
    response: onResponse,
    request: onRequest,
  });
};
