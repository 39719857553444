import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

import withToken from "../../hocs/with-token";

import { LawFirmSearchView, mapStateToProps, mapDispatchToProps } from "./LawFirmSearchView";
import style from "./LawFirmSearchView.style";

const component = compose(
  withStyles(style),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(LawFirmSearchView);

export default withToken(component);
