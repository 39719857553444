function trailingSlash(url) {
  return /\/$/.test(url) ? url : `${url}/`;
}

export default function absoluteUrl(path) {
  return [window.location.origin, window.config.basePath, path]
    .map(trailingSlash)
    .join(``)
    .replace(/\/{1,}/g, `/`);
}
