import { fontFamily } from "../../config/theme";

export default theme => ({
  body: {
    marginBottom: 10 * theme.spacing.unit,
  },
  footer: {
    position: `fixed`,
    bottom: 0,
    alignItems: `center`,
    backgroundColor: theme.palette.primary.main,
    display: `flex`,
    height: 47,
    justifyContent: `center`,
    width: `100vw`,
    zIndex: 1,
  },
  link: {
    fontFamily,
    color: theme.palette.background.paper,
    fontSize: 12,
    fontWeight: `bold`,

    "&:hover": {
      color: theme.palette.background.default,
    },
    padding:`0 ${.375 * theme.spacing.unit}px`,
  },
});
