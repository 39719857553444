import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function HeaderLink(props) {
  const { href, className } = props;
  return /^http|^www|\.co\.uk/.test(href) ? (
    <a className={className} href={/^http/.test(href) ? href : `https://${href}`}>
      &nbsp;
    </a>
  ) : (
    <Link className={className} to={href} />
  );
}

HeaderLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
};

HeaderLink.defaultProps = {
  href: `#`,
  className: ``,
};

export const Header = ({ classes }) => (
  <div className={classes.header}>
    <HeaderLink className={classes.logo} href={window.config.headerLeftIconLink} />
    <HeaderLink className={classes.poweredBy} href={window.config.headerRightIconLink} />
  </div>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
