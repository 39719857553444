import { handleActions } from "redux-actions";
import { assocPath } from "ramda";

import { APP_IS_LOADING } from "../constants/app";

const defaultState = {
  isLoading: false,
};

const appIsLoading = (state, { isLoading }) => assocPath([`isLoading`], isLoading, state);

export default handleActions(
  {
    [APP_IS_LOADING]: appIsLoading,
  },
  defaultState,
);
