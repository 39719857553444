import {
  call, fork, put, select, takeLatest,
} from "redux-saga/effects";
import queryString from "query-string";

import http from "../services/http";
import { SESSION_EMAIL_TOKEN, VALIDATE_TOKEN } from "../constants/session";
import { setRemainingAttempts } from "../actions/account-details";
import { setTokenValidity } from "../actions/session";
import { VERIFICATION } from "../constants/api-endpoints";

export const getLocationSearch = ({
  router: {
    location: { search },
  },
}) => search;
export const getToken = ({ session: { [SESSION_EMAIL_TOKEN]: token } }) => token;

export const fetchIsValidToken = params => http.get(VERIFICATION, {}, params);

export function* validateToken() {
  let isValid = false;
  let token = null;

  try {
    token = yield select(getToken);

    const search = yield select(getLocationSearch);
    const { emailToken = null } = queryString.parse(search, { ignoreQueryPrefix: true });
    if (emailToken) {
      token = emailToken === token ? token : emailToken;
    }

    const response = yield call(fetchIsValidToken, { headers: { [SESSION_EMAIL_TOKEN]: token } });
    const { remainingAttempts } = yield response.json();
    yield put(setRemainingAttempts({ remainingAttempts }));

    isValid = response.status >= 200 && response.status < 300;
  } finally {
    yield put(setTokenValidity({ isValid, token }));
  }
}

export function* watchActions() {
  yield takeLatest(VALIDATE_TOKEN, validateToken);
}

export default fork(watchActions);
