import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  container: {
    padding: 2 * theme.spacing.unit,
  },

  resultContainer: {
    background: theme.palette.grey,
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `#bccad8`,
    padding: `16px 16px 0 16px`,
  },

  resultIconSuccess: {
    display: `block`,
    height: 42,
    margin: `0 auto`,
    width: 42,
    fill: `#aecf4a`,
  },

  resultIconCancel: {
    display: `block`,
    height: 42,
    margin: `0 auto`,
    width: 42,
    fill: `#df2e5b`,
  },

  resultHeading: {
    color: `#0c2b83`,
    fontSize: 24,
    fontWeight: `bold`,
  },

  resultHeaderDivider: {
    width: 12,
    margin: `12px auto`,
  },

  resultHeaderLink: {
    fontWeight: `bold`,
    fontSize: 16,
    margin: `12px 0`,
    "& a": {
      color: `#00aae8`,
    },
  },

  detailsTitle: {
    margin: `16px 0`,
  },

  value: {
    marginBottom: 8,
    fontWeight: `bold`,
  },

  downloadLabel: {
    marginTop: 16,
    marginBottom: 2,
    fontWeight: `normal`,
  },
  downloadButtonContainer: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    margin: `16px 0`,
  },
  buttonDownloadPdf: {
    position: `relative`,
    backgroundColor: `#00aae8`,
    borderRadius: 0,
    color: `#fff`,
    fontSize: 12,
    marginRight: 5,
    whiteSpace: `nowrap`,
    width: `50%`,
    "& svg": {
      position: `absolute`,
      right: 12,
    },
  },
  buttonExit: {
    backgroundColor: `#00236c`,
    borderRadius: 0,
    color: `#fff`,
    fontSize: 12,
    marginLeft: 5,
    width: `50%`,
    "& svg": {
      position: `absolute`,
      right: 12,
    },
  },
});
