import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { MenuItem, Paper, TextField } from "@material-ui/core";
import cx from "classnames";

import trimValue from "../../_lib/trim-value";

import SuggestionItem from "./SuggestionItem";

export const Autocomplete = ({
  classes,
  initialInputValue,
  itemToString,
  label,
  onChange,
  onSelect,
  placeholder,
  suggestions,
  stateReducer,
}) => (
  <Downshift
    onSelect={onSelect}
    onInputValueChange={onChange}
    initialInputValue={initialInputValue}
    itemToString={itemToString}
    stateReducer={stateReducer}
  >
    {({
      getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen,
    }) => {
      const { value: rawValue } = getInputProps();
      const value = trimValue(rawValue);
      const isSuggestionsOpen = isOpen && value.length > 2;
      return (
        <div className={classes.container}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
              },
              ...getInputProps({
                placeholder,
              }),
              ...{ value },
            }}
            label={label}
          />
          <div {...getMenuProps()}>
            <Paper className={cx(classes.paper, { "is-open": isSuggestionsOpen })} square>
              {suggestions.slice(0, 8).map((suggestion, index) => {
                const isHighlighted = highlightedIndex === index;
                const itemProps = getItemProps({ item: suggestion.id });
                return (
                  <MenuItem
                    {...itemProps}
                    key={suggestion.id}
                    selected={isHighlighted}
                    component="div"
                    data-qa="autocomplete-suggestion"
                  >
                    <SuggestionItem suggestion={suggestion} classes={classes} />
                  </MenuItem>
                );
              })}
            </Paper>
          </div>
        </div>
      );
    }}
  </Downshift>
);

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  initialInputValue: PropTypes.string,
  itemToString: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  stateReducer: PropTypes.func,
};

Autocomplete.defaultProps = {
  itemToString: item => item,
  stateReducer: state => state,
  initialInputValue: ``,
  label: ``,
  placeholder: ``,
};
