import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./CaseDetailsView.style";
import { CaseDetailsView, mapStateToProps, mapDispatchToProps } from "./CaseDetailsView";

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(style),
)(CaseDetailsView);
