import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./AttemptsRemaining.style";

import { AttemptsRemaining, mapStateToProps } from "./AttemptsRemaining";

const component = compose(
  withStyles(style),
  connect(mapStateToProps),
)(AttemptsRemaining);

export default withStyles(style)(component);
