import { fork, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { API_ERROR } from "../constants/app";
import { ERROR_PAGE } from "../constants/routes";
import { expired } from "../actions/session";

function isManagedError(code) {
  if (code === 412) return true;
  return false;
}

export function* redirectToErrorPage({ response: { status } }) {
  const statusCode = Number(status);
  if (statusCode < 400 || isManagedError(statusCode)) return;
  yield put(
    push({
      pathname: ERROR_PAGE,
      search: `?code=${status}`,
    }),
  );
  yield put(expired());
}

export function* watchActions() {
  yield takeLatest(API_ERROR, redirectToErrorPage);
}

export default fork(watchActions);
