import { body, contentContainer } from "../../config/theme";

export default theme => ({
  body,
  contentContainer,
  container: {
    padding: 2 * theme.spacing.unit,
  },
  buttonContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    marginTop: 2 * theme.spacing.unit,
  },
  resultIconWarning: {
    display: `block`,
    height: 42,
    margin: `0 auto`,
    width: 42,
    fill: `#eb933d`,
  },
  resultHeaderDivider: {
    width: 12,
    margin: `12px auto`,
  },
  buttons: {
    borderRadius: 0,
    justifyContent: `flex-start`,
    color: `#fff`,
    fontSize: 12,
    marginLeft: 5,
    width: `50%`,
    "& svg": {
      position: `absolute`,
      right: 12,
    },
  },
});
