import { handleActions } from "redux-actions";
import { assocPath } from "ramda";
import { EXPIRED, SET_TOKEN_VALIDATION, SESSION_EMAIL_TOKEN } from "../constants/session";

const defaultState = {
  isValid: false,
  [SESSION_EMAIL_TOKEN]: null,
};

const expired = (state) => {
  let newState = state;

  newState = assocPath([`isValid`], false, newState);
  newState = assocPath([SESSION_EMAIL_TOKEN], null, newState);

  return newState;
};

const setToken = (state, { isValid, token }) => {
  let newState = state;
  newState = assocPath([`isValid`], isValid, newState);
  newState = assocPath([SESSION_EMAIL_TOKEN], token, newState);

  return newState;
};

export default handleActions(
  {
    [EXPIRED]: expired,
    [SET_TOKEN_VALIDATION]: setToken,
  },
  defaultState,
);
