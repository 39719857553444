import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const startUp = () => {
  const rootElement = document.getElementById(`root`);

  ReactDOM.render(<App />, rootElement);

  serviceWorker.unregister();
};

window
  .fetch(`config`)
  .then(response => response.json())
  .then((config) => {
    window.config = config;
    startUp();
  })
  .catch((error) => {
    /* eslint-disable no-console */
    console.error(error);
    console.error(`Could not get config from the config endpoint`);
    /* eslint-enable */
  });
