import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Tooltip } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";

import style from "./Tooltip.style";

class CustomTooltip extends PureComponent {
  state = {
    open: false,
  };

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  handleOnClick = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    const { classes, title, placement } = this.props;
    return (
      <Tooltip
        title={title}
        placement={placement}
        classes={{ tooltip: classes.tooltip }}
        onClose={this.handleTooltipClose}
        onOpen={this.handleTooltipOpen}
        open={this.state.open}
      >
        <ErrorIcon className={classes.tooltipIcon} onClick={this.handleOnClick} />
      </Tooltip>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
};

export default withStyles(style)(CustomTooltip);
