import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  Typography, Button, Link, Divider,
} from "@material-ui/core";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Note as NoteIcon,
} from "@material-ui/icons";
import { HashLink } from "react-router-hash-link";
import dayjs from "dayjs";

import absoluteUrl from "../../_lib/absolute-url";

import AttemptsRemaining from "../attempts-remaining";
import { CLIENT_ACCOUNT_VERIFICATION_REPORT } from "../../constants/api-endpoints";
import { resetSelectedLawFirm } from "../../actions/account-details";

import Header from "../header";
import Footer from "../footer";
import ButtonExit from "./ButtonExit";

export const BankAccountResultView = ({
  classes,
  submissionResult,
  selectedLawFirm,
  history,
  resetSelected,
}) => (
  <div className={classes.body}>
    <Header />
    <section className={classes.contentContainer}>
      <div className={`${classes.container} form-container`}>
        {submissionResult.status >= 200 && submissionResult.status <= 300 && (
          <>
            <CheckCircleIcon className={classes.resultIconSuccess} />
            <Typography variant="h3" align="center" className={classes.resultHeading}>
              {`It's a match`}
            </Typography>
            <Divider variant="middle" className={classes.resultHeaderDivider} />

            <Typography align="center">
              The details you have provided have matched the records that we hold for the law firm.
            </Typography>

            <Typography variant="h6" align="center" className={classes.resultHeaderLink}>
              <Link
                to="/faq#what-if-the-bank-details-dont-match"
                component={HashLink}
                onClick={() => {
                  window.ga(
                    `send`,
                    `pageview`,
                    absoluteUrl(`faq#what-if-the-bank-details-dont-match`),
                  );
                }}
              >
                Am I ok to now send money to the law firm?
              </Link>
            </Typography>
          </>
        )}
        {submissionResult.status >= 400 && (
          <>
            <CancelIcon className={classes.resultIconCancel} />
            <Typography variant="h3" align="center" className={classes.resultHeading}>
              No Match
            </Typography>
            <Divider variant="middle" className={classes.resultHeaderDivider} />
            <Typography align="center">
              The details you have provided do not match the records that we hold for the law firm.
              Please check the details below and if they are as provided by the law firm you should
              speak to your contact at the law firm by phone before transferring money.
            </Typography>
            <Typography variant="h6" align="center" className={classes.resultHeaderLink}>
              <Link
                to="faq#what-if-the-bank-details-dont-match"
                component={HashLink}
                onClick={() => {
                  window.ga(
                    `send`,
                    `pageview`,
                    absoluteUrl(`faq#what-if-the-bank-details-dont-match`),
                  );
                }}
              >
                Should I transfer my money?
              </Link>
            </Typography>
          </>
        )}
        <>
          <Typography align="center" className={classes.detailsTitle}>
            You asked us to check these details on{` `}
            {dayjs(submissionResult.dateTime).format(`DD/MM/YYYY`)} at{` `}
            {dayjs(submissionResult.dateTime).format(`HH:mm`)}:
          </Typography>
          <div className={classes.resultContainer}>
            <Typography variant="h6">Company Name:</Typography>
            <Typography className={classes.value}>{selectedLawFirm.companyName}</Typography>
            <Typography variant="h6">Trading Name(s):</Typography>
            <Typography className={classes.value}>{selectedLawFirm.tradingName}</Typography>
            <Typography variant="h6">Address:</Typography>
            <Typography className={classes.value}>{selectedLawFirm.address}</Typography>
            <Typography variant="h6">Who is the law firm regulated by?:</Typography>
            <Typography className={classes.value}>{selectedLawFirm.regulator}</Typography>
            <Typography variant="h6">Sort Code:</Typography>
            <Typography className={classes.value}>{selectedLawFirm.sortCode}</Typography>
            <Typography variant="h6">Account Number:</Typography>
            <Typography className={classes.value}>{selectedLawFirm.accountNumber}</Typography>
          </div>

          <Typography className={classes.downloadLabel}>
            Download a copy of the results for your records
          </Typography>

          <div className={classes.downloadButtonContainer}>
            <Button
              className={classes.buttonDownloadPdf}
              href={`${process.env.REACT_APP_API_PREFIX}${CLIENT_ACCOUNT_VERIFICATION_REPORT}/${
                submissionResult.id
              }`}
              disableRipple
              disableFocusRipple
            >
              Download PDF
              <NoteIcon />
            </Button>
            <ButtonExit
              className={classes.buttonExit}
              result={submissionResult}
              history={history}
              resetSelected={resetSelected}
              submissionResult={submissionResult}
            />
          </div>
          <div>
            <AttemptsRemaining />
          </div>
        </>
      </div>
    </section>
    <Footer />
  </div>
);

BankAccountResultView.propTypes = {
  classes: PropTypes.object.isRequired,
  submissionResult: PropTypes.object.isRequired,
  selectedLawFirm: PropTypes.object.isRequired,
  history: PropTypes.object,
  resetSelected: PropTypes.func,
};

BankAccountResultView.defaultProps = {
  history: {},
  resetSelected: () => {},
};

export const mapStateToProps = ({ accountDetails: { submissionResult, selectedLawFirm } }) => ({
  submissionResult,
  selectedLawFirm,
});

export const mapDispatchToProps = dispatch => ({
  resetSelected: bindActionCreators(resetSelectedLawFirm, dispatch),
});
