/* eslint jsx-a11y/label-has-associated-control:off */
/* eslint jsx-a11y/label-has-for:off */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as R from "ramda";
import ReCAPTCHA from "react-google-recaptcha";
import { HashLink } from "react-router-hash-link";
import {
  Button,
  FormHelperText,
  Link,
  Typography,
  FormControl,
  InputBase,
  Icon,
  Divider,
} from "@material-ui/core";

import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";

import {
  FIELD_EMAIL,
  FIELD_SURNAME,
  FIELD_POSTCODE,
  FIELD_RECAPTCHA,
} from "../../constants/case-details";

import absoluteUrl from "../../_lib/absolute-url";
import trimValue from "../../_lib/trim-value";
import {
  updateField,
  updateFieldValidation,
  submit,
  resetSubmission,
} from "../../actions/case-details";

import Header from "../header";
import Footer from "../footer";
import Modal from "../modal";
import Tooltip from "../tooltip";
import Loading from "../loading";

export const CaseDetailsView = ({
  classes,
  fields: {
    [FIELD_EMAIL]: email,
    [FIELD_SURNAME]: surname,
    [FIELD_POSTCODE]: postcode,
    [FIELD_RECAPTCHA]: ReCAPTCHAField,
  } = {},
  resetSubmissionAction,
  submitAction,
  submissionResult,
  updateFieldAction,
  updateFieldValidationAction,
  appIsLoading,
}) => {
  const [values, setValue] = useState(
    [FIELD_EMAIL, FIELD_SURNAME, FIELD_POSTCODE].reduce(
      (acc, field) => ({ ...acc, [field]: `` }),
      {},
    ),
  );

  const recaptchaEl = useRef(null);

  const onChangeField = field => (ev) => {
    setValue(R.assoc(field, ev.target.value, values));
  };

  const onBlurField = field => ({ target: { value } }) => {
    updateFieldAction({ field, value: trimValue(value) });
  };

  const onFocusField = field => () => {
    updateFieldValidationAction({ field });
  };

  const onChangeReCAPTCHA = (value) => {
    updateFieldAction({ field: FIELD_RECAPTCHA, value });
  };

  const onClickResendLink = () => {
    recaptchaEl.current.reset();
    updateFieldAction({ field: FIELD_RECAPTCHA, value: null });
    resetSubmissionAction();
  };

  const submitForm = (e) => {
    e.preventDefault();
    submitAction();
  };

  const areFieldsValid = [email, surname, postcode, ReCAPTCHAField].reduce(
    (acc, value) => acc && value.isValid,
    true,
  );

  return (
    <div className={classes.body}>
      <Header />
      <section className={classes.contentContainer}>
        <div className={`${classes.container} form-container`}>
          {appIsLoading && <Loading />}
          <div align="center">
            <Typography variant="h3" className={classes.resultHeading}>
              Enter Case Details
            </Typography>
            <div>
              <Link
                to="faq#what-is-lms-bank-account-check"
                component={HashLink}
                onClick={() => {
                  window.ga(`send`, `pageview`, absoluteUrl(`faq#what-is-lms-bank-account-check`));
                }}
              >
                What is Bank Account Check?
              </Link>
            </div>
            <div>
              <Link
                to="faq#who-is-lms"
                component={HashLink}
                onClick={() => {
                  window.ga(`send`, `pageview`, absoluteUrl(`faq#who-is-lms`));
                }}
              >
                Who are LMS?
              </Link>
            </div>
            <Typography>
              To allow access to this service we need to check your surname and postcode against our
              records. We will then send you an email containing a personalised link which will take
              you straight to Bank Account Check.
            </Typography>
          </div>
          <Divider variant="middle" />
          <form method="POST">
            <FormControl variant="outlined" error={email.isValid === false} fullWidth>
              <div className={classes.inputLabel}>
                <label htmlFor={FIELD_EMAIL} className={classes.formLabel}>
                  Email Address
                </label>
              </div>
              <div className={classes.inputBox}>
                <InputBase
                  id={FIELD_EMAIL}
                  value={values[FIELD_EMAIL]}
                  onChange={onChangeField(FIELD_EMAIL)}
                  onBlur={onBlurField(FIELD_EMAIL)}
                  onFocus={onFocusField(FIELD_EMAIL)}
                  fullWidth
                />
                <Icon className="validation" hidden={!email.isValid}>
                  check-circle
                </Icon>
              </div>
              <FormHelperText className={classes.inputErrorMessage} error={email.isValid === false}>
                {email.errorMessage}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              error={surname.isValid === false}
              fullWidth
              className={classes.formControlWithTooltip}
            >
              <div className={classes.inputLabel}>
                <label htmlFor={FIELD_SURNAME} className={classes.formLabelWithTooltip}>
                  Surname
                </label>
                <Tooltip
                  title="The surname provided must match the Mortgage Offer you have received from the lender"
                  placement="right"
                />
              </div>
              <div className={classes.inputBox}>
                <InputBase
                  id={FIELD_SURNAME}
                  value={values[FIELD_SURNAME]}
                  onChange={onChangeField(FIELD_SURNAME)}
                  onBlur={onBlurField(FIELD_SURNAME)}
                  fullWidth
                />
                <Icon className="validation" hidden={!surname.isValid}>
                  check-circle
                </Icon>
              </div>
              <FormHelperText
                className={classes.inputErrorMessage}
                error={surname.isValid === false}
              >
                {surname.errorMessage}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              error={postcode.isValid === false}
              fullWidth
              className={classes.formControlWithTooltip}
            >
              <div className={classes.inputLabel}>
                <label htmlFor={FIELD_SURNAME} className={classes.formLabelWithTooltip}>
                  Purchase/Remortgage property postcode
                </label>
                <Tooltip
                  title="The postcode provided must match the Mortgage Offer you have received from the lender"
                  placement="right"
                />
              </div>
              <div className={classes.inputBox}>
                <InputBase
                  id={FIELD_POSTCODE}
                  value={values[FIELD_POSTCODE] ? values[FIELD_POSTCODE].toUpperCase() : ``}
                  onChange={onChangeField(FIELD_POSTCODE)}
                  onBlur={onBlurField(FIELD_POSTCODE)}
                  fullWidth
                  className="all-caps"
                />
                <Icon className="validation" hidden={!postcode.isValid}>
                  check-circle
                </Icon>
              </div>
              <FormHelperText
                className={classes.inputErrorMessage}
                error={postcode.isValid === false}
              >
                {postcode.errorMessage}
              </FormHelperText>
            </FormControl>

            <div className={classes.reCaptchaContainer}>
              <ReCAPTCHA
                ref={recaptchaEl}
                sitekey={window.config.reCaptchaKey}
                onChange={onChangeReCAPTCHA}
                className={classes.textField}
              />
              <FormHelperText error>{ReCAPTCHAField.errorMessage}</FormHelperText>
            </div>

            <div className={classes.nextButtonContainer}>
              <Button
                data-submit-form
                type="submit"
                variant="contained"
                color={areFieldsValid ? `primary` : `default`}
                onClick={submitForm}
                fullWidth
                disabled={!areFieldsValid}
              >
                Next <ChevronRightIcon className={classes.rightIcon} />
              </Button>
            </div>
          </form>
          <Modal
            isOpen={
              !appIsLoading && submissionResult.isCompleted && submissionResult.status === 202
            }
            onClose={resetSubmissionAction}
            title="Email sent"
            paragraphs={[
              `Thank you. We have sent you an email with a personalised link. The email is sent 
          instantly, if you don’t receive it please check your spam/junk folder.`,
              `When you have used the link to go to LMS Bank Account Check website you will be 
          asked to search for your law firm by postcode and name. Following this you will 
          input the law firm bank details you have been provided with and we will confirm if these match.`,
              `Not received your email within a few minutes? Please click below to go back to the previous page 
          then check your email address and resubmit your request. You will need to confirm you are a human again`,
            ]}
            buttonComponent={
              /* istanbul ignore next */ () => (
                <div className={classes.modalButtonContainer}>
                  <Button onClick={onClickResendLink} variant="contained" color="primary" fullWidth>
                    Resend the link
                  </Button>
                </div>
    )
            }
          />
          <Modal
            isOpen={
              !appIsLoading && submissionResult.isCompleted && submissionResult.status !== 202
            }
            onClose={resetSubmissionAction}
            title="Case not found"
            paragraphs={[
              `We have been unable to match your surname and postcode to our records of customers
          who are currently remortgaging or purchasing a property
          with ${window.config.bankName}.`,
              `Please ensure the surname and postcode match those on the mortgage offer sent to
          you by the lender and try again.`,
              `It may be that we do not have your details which unfortunately means that this
          service is unavailable to you. Please speak to your law firm directly to check
          their bank details.`,
            ]}
            buttonComponent={
              /* istanbul ignore next */ () => (
                <div className={classes.modalButtonContainer}>
                  <Button
                    onClick={resetSubmissionAction}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Edit form
                  </Button>
                </div>
    )
            }
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

CaseDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.shape({
    [FIELD_EMAIL]: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    [FIELD_SURNAME]: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    [FIELD_POSTCODE]: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    [FIELD_RECAPTCHA]: PropTypes.shape({
      value: PropTypes.string,
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
  }).isRequired,
  resetSubmissionAction: PropTypes.func.isRequired,
  submissionResult: PropTypes.shape({
    isCompleted: PropTypes.bool,
    status: PropTypes.number,
  }).isRequired,
  submitAction: PropTypes.func.isRequired,
  updateFieldAction: PropTypes.func.isRequired,
  updateFieldValidationAction: PropTypes.func.isRequired,
  appIsLoading: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({ caseDetails: { fields, submissionResult }, app = {} }) => ({
  fields,
  submissionResult,
  appIsLoading: app.isLoading,
});

export const mapDispatchToProps = dispatch => ({
  resetSubmissionAction: bindActionCreators(resetSubmission, dispatch),
  submitAction: bindActionCreators(submit, dispatch),
  updateFieldAction: bindActionCreators(updateField, dispatch),
  updateFieldValidationAction: bindActionCreators(updateFieldValidation, dispatch),
});
