import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import style from "./style";

function Loading(props) {
  const { classes } = props;
  return (
    <div className={classes.container} id="loading-spinner">
      <div className={classes.overlay} />
      <div className={classes.message}>
        <p>Please wait...</p>
        <div className={classes.spinner}>
          {Object.keys(new Array(12).fill()).map(e => (
            <div key={`spinner-${e}`} className={classes.spinnerSlice} />
          ))}
        </div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Loading);
