import { handleActions } from "redux-actions";
import * as R from "ramda";
import {
  RESET_SUBMISSION,
  SUBMIT,
  SUBMIT_FAILURE,
  SUBMIT_SUCCESS,
  UPDATE_FIELD,
  UPDATE_FIELD_VALIDATION,
  FIELD_EMAIL,
  FIELD_SURNAME,
  FIELD_POSTCODE,
  FIELD_RECAPTCHA,
} from "../constants/case-details";

import Validator from "../services/validator";

const defaultState = {
  fields: {
    [FIELD_EMAIL]: {
      value: ``,
      isValid: null,
    },
    [FIELD_SURNAME]: {
      value: ``,
      isValid: null,
    },
    [FIELD_POSTCODE]: {
      value: ``,
      isValid: null,
    },
    [FIELD_RECAPTCHA]: {
      value: ``,
      isValid: null,
    },
  },
  submissionResult: {
    isCompleted: false,
    status: null,
  },
};

const validateField = ({ field, value }) => {
  const validation = new Validator(value);

  if (field === FIELD_EMAIL) {
    return validation.isRequired().isEmail();
  }

  if (field === FIELD_SURNAME) {
    return validation.isRequired().isName();
  }

  if (field === FIELD_POSTCODE) {
    return validation.isRequired().isPostCode();
  }

  /* istanbul ignore else */
  if (field === FIELD_RECAPTCHA) {
    return validation.isRequired().isReCAPTCHA();
  }

  /* istanbul ignore next */
  return validation;
};

const updateField = (state, { value, field }) => {
  let newState = state;

  if (R.has(field)(newState.fields)) {
    const validatedField = validateField({ field, value });

    newState = R.assocPath([`fields`, field, `value`], value, newState);
    newState = R.assocPath([`fields`, field, `isValid`], validatedField.isValid, newState);
    newState = R.assocPath(
      [`fields`, field, `errorMessage`],
      validatedField.errorMessage,
      newState,
    );
  }

  return newState;
};

const updateFieldValidation = (state, { field, isValid, errorMessage }) => {
  let newState = state;
  newState = R.assocPath([`fields`, field, `isValid`], isValid, newState);
  newState = R.assocPath([`fields`, field, `errorMessage`], errorMessage, newState);
  return newState;
};

const resetSubmission = state => R.assocPath([`submissionResult`], defaultState.submissionResult, state);


const submit = (state) => {
    let newState = state;

  // when resend the link, the recaptcha may have expired
  if (R.isNil(state.fields.recaptcha.isValid)) {
    return resetSubmission(state);
  }

  R.keys(state.fields).forEach((field) => {
    if (R.isNil(state.fields[field].isValid)) {
      const validatedField = validateField({
        field,
        value: state.fields[field].value,
      });

      newState = R.assocPath([`fields`, field, `isValid`], validatedField.isValid, newState);
      newState = R.assocPath(
        [`fields`, field, `errorMessage`],
        validatedField.errorMessage,
        newState,
      );
    }
  });

  return newState;
};

const submitComplete = (state, { error = 202 }) => {
  let newState = state;
  newState = R.assocPath([`submissionResult`, `isCompleted`], true, newState);
  newState = R.assocPath([`submissionResult`, `status`], error, newState);
  return newState;
};


export default handleActions(
  {
    [UPDATE_FIELD]: updateField,
    [UPDATE_FIELD_VALIDATION]: updateFieldValidation,
    [RESET_SUBMISSION]: resetSubmission,
    [SUBMIT]: submit,
    [SUBMIT_SUCCESS]: submitComplete,
    [SUBMIT_FAILURE]: submitComplete,
  },
  defaultState,
);
