import { body } from "../../config/theme";

export default () => ({
  body: {
    ...body,
    paddingBottom: 0,
  },
  background: {
    height: `100%`,
    width: `100%`,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/bg-landing.jpg)`,
    backgroundPosition: `50% 50%`,
    backgroundSize: `cover`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  logo: {
    width: 260,
    height: 85,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/logo-landing-bac.png)`,
    backgroundSize: `contain`,
    margin: `0 auto`,
  },
  poweredBy: {
    width: 87,
    height: 55,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/logo-landing-powered.png)`,
    backgroundSize: `contain`,
    margin: `32px auto`,
  },
  cta: {
    position: `relative`,
    width: 280,
    height: 40,
    color: `#fff`,
    backgroundColor: `#00aae8`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    fontSize: 14,
    fontWeight: `bold`,
    borderRadius: 0,
    "& svg": {
      position: `absolute`,
      left: 40,
    },
  },
});
