import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography } from "@material-ui/core";

export default function SelectedLawFirm(props) {
  const { classes, selectedLawFirm, isLawFirmNotListed } = props;
  if (isLawFirmNotListed) {
    return (
      <Paper square className={classes.messageLawFirmNotListed}>
        <Typography>
          Please check the search criteria above as we have been unable to find a law firm that
          matches the search.
        </Typography>
        <Typography>
          LMS Bank Account Check covers law firms that fulfil 97% of conveyancing transactions in
          the UK, however, sometimes a law firms bank details may not be available to check against.
          Please speak to your law firm directly to check their bank details.
        </Typography>
      </Paper>
    );
  }
  if (!selectedLawFirm) return null;

  if (selectedLawFirm.isUnderReview) {
    return (
      <Paper square className={classes.messageLawFirmNotListed}>
        <Typography>
          Unfortunately you are unable to check this law firms details at the moment. Please speak
          to your law firm directly to check their bank details.
        </Typography>
      </Paper>
    );
  }

  const {
    companyName, tradingName, address, regulator,
  } = selectedLawFirm;
  return (
    <>
      <Typography>Your law firm`s details:</Typography>
      <Paper square>
        <Typography variant="h6">Company Name</Typography>
        <Typography>{companyName}</Typography>
        <Typography variant="h6">Trading Name(s)</Typography>
        <Typography>{tradingName}</Typography>
        <Typography variant="h6">Address</Typography>
        <Typography>{address}</Typography>
        <Typography variant="h6">Who is the law firm regulated by?:</Typography>
        <Typography>{regulator}</Typography>
      </Paper>
    </>
  );
}

SelectedLawFirm.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLawFirm: PropTypes.object,
  isLawFirmNotListed: PropTypes.bool.isRequired,
};

SelectedLawFirm.defaultProps = {
  selectedLawFirm: null,
};
