import React from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import pluralize from "pluralize";
import { HashLink } from "react-router-hash-link";

import absoluteUrl from "../../_lib/absolute-url";

export const AttemptsRemaining = ({ attempts, classes }) => (
  <section className={classes.container}>
    <Typography>
      You have {attempts} {pluralize(`attempt`, attempts)} remaining
    </Typography>
    <div>
      <Link
        to="/faq#attempts-remaining-limit-reached"
        component={HashLink}
        onClick={() => {
          window.ga(`send`, `pageview`, absoluteUrl(`faq#attempts-remaining-limit-reached`));
        }}
      >
        What does this mean?
      </Link>
    </div>
  </section>
);

AttemptsRemaining.propTypes = {
  attempts: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

AttemptsRemaining.defaultProps = {
  attempts: 3,
};

export const mapStateToProps = ({ accountDetails: { attempts } }) => ({
  attempts,
});
