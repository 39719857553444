import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const Footer = ({ classes }) => (
  <div className={classes.footer}>
    <Link
      to={`${process.env.PUBLIC_URL}${window.config.termsOfUsePath}`}
      target="_blank"
      className={classes.link}
    >
      Terms and Conditions
    </Link>
    <Link
      to={`${process.env.PUBLIC_URL}${window.config.privacyNoticePath}`}
      target="_blank"
      className={classes.link}
    >
      Privacy Policy
    </Link>
  </div>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};
