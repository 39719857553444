import { bindActionCreators } from 'redux';
import { validateToken } from '../../actions/session';

export const TokenedView = ({ children, validateTokenAction }) => {
  validateTokenAction();
  return children;
};

export const mapDispatchToProps = dispatch => ({
  validateTokenAction: bindActionCreators(validateToken, dispatch),
});
