function spinnerDivsTransform() {
  return Object.keys(new Array(13).fill()).reduce((acc, nth) => {
    acc[`&:nth-child(${nth})`] = {
      transform: `rotate(${(nth - 1) * 30}deg)`,
      animationDelay: `-${1.1 - (nth - 1) * 0.1}s`,
    };
    return acc;
  }, {});
}

export default theme => ({
  container: {
    position: `fixed`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    width: `100%`,
    height: `calc(100% + 50px)`,
    top: -50,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1301,
  },
  overlay: {
    position: `absolute`,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: `100%`,
    height: `100%`,
    backgroundColor: `#00236c`,
    opacity: 0.7,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    zIndex: 5,
  },
  message: {
    boxSizing: `border-box`,
    position: `relative`,
    zIndex: 6,
    width: `100%`,
    maxWidth: `30rem`,
    margin: `0 1rem`,
    padding: `1rem 0`,
    display: `flex`,
    right: theme.spacing.unit,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    backgroundColor: `#fff`,
    "& p": {
      ...theme.typography.body2,
      margin: `0 0 1rem 0`,
      padding: `0 1rem`,
      fontSize: `1.4em`,
      fontWeight: `bold`,
    },
  },
  spinner: {
    display: `inline-block`,
    position: `relative`,
    width: 64,
    height: 64,
  },
  spinnerSlice: {
    transformOrigin: `32px 32px`,
    animation: `lms-spinner 1.2s linear infinite`,
    "&::after": {
      content: `" "`,
      display: `block`,
      position: `absolute`,
      top: 3,
      left: 29,
      width: 5,
      height: 14,
      borderRadius: `20%`,
      backgroundColor: `#00aae5`,
    },
    ...spinnerDivsTransform(),
  },
});
