import queryString from "query-string";

function serialize(query) {
  return query && Object.keys(query).length > 0 ? `?${queryString.stringify(query)}` : ``;
}

class HTTPError extends Error {
  constructor(status, statusText) {
    super();
    this.status = status;
    this.statusText = statusText;
  }
}

function catchErrors(response, options) {
  const { ignoreErrorStatusCodes } = options;
  if (ignoreErrorStatusCodes && ignoreErrorStatusCodes.test(String(response.status))) {
    return response;
  }
  if (!response.ok) {
    throw new HTTPError(response.status, response.statusText);
  }
  return response;
}

function fetch(
  endpoint,
  {
    query, method, headers, body,
  },
  options = {
    ignoreErrorStatusCodes: null, // regex matching what error codes to ignore
  },
) {
  return window
    .fetch(`${process.env.REACT_APP_API_PREFIX}${endpoint}${serialize(query)}`, {
      ...(method && { method }),
      ...(headers && { headers }),
      ...(body && { body: JSON.stringify(body) }),
    })
    .then(response => catchErrors(response, options));
}

function get(endpoint, query = {}, params = {}, options) {
  return fetch(endpoint, { query, ...params }, options);
}

function post(endpoint, params = {}, options) {
  const headers = {
    Accept: `application/json`,
    "Content-Type": `application/json`,
  };

  return fetch(
    endpoint,
    {
      ...params,
      method: `POST`,
      headers: { ...params.headers, ...headers },
    },
    options,
  );
}

export default {
  get,
  post,
  HTTPError,
};
