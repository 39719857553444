export default theme => ({
  header: {
    alignItems: `center`,
    backgroundColor: theme.palette.background.paper,
    display: `flex`,
    height: 50,
    justifyContent: `space-between`,
    padding: `0 ${2 * theme.spacing.unit}px`,
    position: `fixed`,
    top: 0,
    width: `100%`,
    zIndex: 2,
  },
  logo: {
    width: 100,
    height: 33,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/logo-bank-account-check.png)`,
    backgroundSize: `contain`,
  },
  poweredBy: {
    width: 55,
    height: 33,
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/logo-lms-powered.png)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
  },
});
