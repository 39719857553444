import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const fontFamily = [
  `Trebuchet MS`,
  `Lucida Grande`,
  `Lucida Sans Unicode`,
  `Lucida Sans`,
  `Tahoma`,
  `sans-serif`,
].join(`,`);

export default createMuiTheme({
  typography: {
    default: {
      color: `#0c2b83`,
    },
    fontFamily,
    useNextVariants: true,
    h2: {
      fontSize: `1rem`,
    },
    h3: {
      fontSize: `1.5rem`,
      color: `inherit`,
      marginBottom: `.5em`,
    },
    h6: {
      color: `#7288ac`,
      fontSize: `12px`,
    },
    body2: {
      color: `#00206c`,
    },
  },
  palette: {
    background: {
      default: `#EFEFF0`,
    },
    primary: {
      light: `#496493`,
      main: `#1C3E79`,
      dark: `#132b54`,
      contrastText: `#fff`,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        lineHeight: 2,
        color: `#1c3e79`,
      },
      shrink: {
        transform: `translate(0, -5px) scale(0.9) !important`,
        color: `inherit`,
      },
    },
    MuiButton: {
      contained: {
        padding: `10px 10px`,
        boxShadow: `0px 0px 41px -5px rgba(0,0,0,0.28)`,
        borderRadius: 0,
        color: `#fff`,
        backgroundColor: grey[400],
      },
      containedPrimary: {
        backgroundColor: `#00aae8`,
        color: `#fff`,
      },
      containedSecondary: {
        color: `#fff`,
        backgroundColor: `#00236c`,
      },
    },
    MuiFormHelperText: {
      filled: {
        margin: `.5em 0 1.5em`,
      },
    },
    MuiInputBase: {
      input: {
        marginTop: 24,
        position: `relative`,
        backgroundColor: `#fff`,
        border: `none`,
        width: `100%`,
        padding: `14px 16px`,
      },
    },
    MuiInputAdornment: {
      root: {
        marginTop: 24,
      },
      positionEnd: {
        marginLeft: 9,
      },
    },
    MuiListItem: {
      button: {
        backgroundColor: `#fff`,
      },
    },
    MuiLink: {
      underlineHover: {
        textDecoration: `underline`,
        display: `inline-block`,
        lineHeight: `1.5em`,
        fontFamily: `Trebuchet MS`,
        fontSize: `.9rem`,
        color: `#00aae5`,
        fontWeight: `bold`,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: `none !important`,
        border: `1px solid #00aae5`,
        padding: 16,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: `#00aae5`,
      },
    },
    MuiDivider: {
      middle: {
        marginLeft: `inherit`,
        marginRight: `inherit`,
        margin: `1em 0`,
      },
    },
  },
});

export const body = {
  position: `relative`,
  height: `100%`,
  overflowX: `hidden`,
};

export const contentContainer = {
  position: `relative`,
  top: 50,
  height: `calc(100% - 47px - 50px)`,
  padding: `2rem 0 2rem 0`,
  overflowY: `auto`,
};
