import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

import withToken from "../../hocs/with-token";

import style from "./AccountDetailsView.style";
import { AccountDetailsView, mapStateToProps, mapDispatchToProps } from "./AccountDetailsView";

const component = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(style),
)(AccountDetailsView);

export default withToken(component);
