export const LANDING_PAGE = `/`;
export const CASE_DETAILS_PAGE = `/case-details`;
export const BANK_ACCOUNT_RESULT_PAGE = `/bank-account-result`;
export const ERROR_PAGE = `/error`;
export const ACCOUNT_DETAILS_PAGE = `/account-details`;
export const LAW_FIRM_SEARCH_PAGE = `/law-firm-search`;
export const FAQ_PAGE = `/faq`;

export const ERROR_UNAUTHORIZED = 401;
export const ERROR_FORBIDDEN = 403;
export const ERROR_TOO_MANY_ATTEMPTS = 429;
export const ERROR_CODE_EXPIRED = 440;
