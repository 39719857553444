import {
  EXPIRED,
  VALIDATE_TOKEN,
  SET_TOKEN_VALIDATION,
} from '../constants/session';

export const expired = () => ({
  type: EXPIRED,
});

export const validateToken = () => ({
  type: VALIDATE_TOKEN,
});

export const setTokenValidity = payload => ({
  type: SET_TOKEN_VALIDATION,
  ...payload,
});
