import { handleActions } from "redux-actions";
import { assoc, assocPath } from "ramda";
import {
  SET_REMAINING_ATTEMPTS,
  SET_SUBMISSION_RESULT,
  SET_LAW_FIRMS_LIST,
  RESET_LAW_FIRM_RESULTS,
  SELECT_LAW_FIRM,
  RESET_SELECTED_LAW_FIRM,
  SUBMIT,
} from "../constants/account-details";

const defaultState = {
  attempts: 3,
  lawFirmsList: {},
  selectedLawFirm: null,
  submissionResult: {
    status: null,
    text: null,
  },
};

const setRemainingAttempts = (state, { remainingAttempts }) => assocPath([`attempts`], remainingAttempts, state);

const setLawFirmsList = (state, { list }) => assocPath([`lawFirmsList`], list, state);

const setSubmissionResult = (state, payload) => assoc(`submissionResult`, payload, state);

const resetLawFirmResults = state => assocPath([`lawFirmsList`], {}, state);

const selectLawFirm = (state, { firm }) => assocPath([`selectedLawFirm`], firm, state);

const resetSelectedLawFirm = state => assocPath([`selectedLawFirm`], null, state);

const submit = (state, { bankDetails }) => assocPath(
  [`selectedLawFirm`],
  {
    ...state.selectedLawFirm,
    ...bankDetails,
  },
  state,
);

export default handleActions(
  {
    [SET_SUBMISSION_RESULT]: setSubmissionResult,
    [SET_REMAINING_ATTEMPTS]: setRemainingAttempts,
    [SET_LAW_FIRMS_LIST]: setLawFirmsList,
    [RESET_LAW_FIRM_RESULTS]: resetLawFirmResults,
    [SELECT_LAW_FIRM]: selectLawFirm,
    [RESET_SELECTED_LAW_FIRM]: resetSelectedLawFirm,
    [SUBMIT]: submit,
  },
  defaultState,
);
